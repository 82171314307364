import React, { useState, useEffect } from 'react';
import '../styles/BookingRequestsScreen.scss';
import trashIcon from '../icons/trash.svg';
import checkIcon from '../icons/check.svg';

interface TimeSlot {
  start: string;
  end: string;
}

interface BookingRequest {
  _id: string;
  userId: {
    name: string;
    phone: string;
  };
  roomId: {
    name: string;
  };
  date: string;
  timeSlots: TimeSlot[];
  type: 'individual' | 'group' | 'automatic';
  numberOfPeople: number;
  eventType: string;
  status: 'pending' | 'confirmed';
  totalPrice?: number;
}

interface GroupedBookings {
  [key: string]: BookingRequest[];
}

// Утилиты для форматирования времени
const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const normalizeTime = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const formatTimeSlots = (timeSlots: TimeSlot[]): string => {
  if (!Array.isArray(timeSlots) || timeSlots.length === 0) {
    return '';
  }

  // Сортируем слоты по времени начала
  const sortedSlots = [...timeSlots].sort((a, b) => 
    timeToMinutes(a.start) - timeToMinutes(b.start)
  );

  const mergedSlots: { start: string; end: string }[] = [];
  let currentSlot = { ...sortedSlots[0] };

  for (let i = 1; i < sortedSlots.length; i++) {
    if (timeToMinutes(currentSlot.end) === timeToMinutes(sortedSlots[i].start)) {
      // Слоты можно объединить
      currentSlot.end = sortedSlots[i].end;
    } else {
      // Слоты нельзя объединить
      mergedSlots.push({ ...currentSlot });
      currentSlot = { ...sortedSlots[i] };
    }
  }
  mergedSlots.push(currentSlot);

  // Форматируем результат с нормализацией времени
  return mergedSlots
    .map(slot => `${normalizeTime(slot.start)}-${normalizeTime(slot.end)}`)
    .join(', ');
};

const formatDateKey = (dateString: string) => {
  const date = new Date(dateString);
  // Создаем новую дату в UTC
  const utcDate = new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  ));
  
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const months = [
    'ЯНВ.', 'ФЕВ.', 'МАР.', 'АПР.', 'МАЯ', 'ИЮН.', 
    'ИЮЛ.', 'АВГ.', 'СЕНТ.', 'ОКТ.', 'НОЯБ.', 'ДЕК.'
  ];
  const month = months[utcDate.getUTCMonth()];
  const year = utcDate.getUTCFullYear();
  const weekday = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'][utcDate.getUTCDay()];
  
  return `${day} ${month} ${year} (${weekday})`;
};

const BookingRequestsScreen: React.FC = () => {
  const [pendingBookings, setPendingBookings] = useState<BookingRequest[]>([]);
  const [confirmedBookings, setConfirmedBookings] = useState<BookingRequest[]>([]);
  const [priceInputs, setPriceInputs] = useState<{ [key: string]: string }>({});
  const [priceErrors, setPriceErrors] = useState<{ [key: string]: string }>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [showAutoBookings, setShowAutoBookings] = useState(true);

  useEffect(() => {
    fetchBookings();
  }, []);

  const normalizeString = (str: string): string => {
    return str
      .toLowerCase()
      .replace('ё', 'е')
      .replace(/\s+/g, ' ')
      .trim();
  };

  useEffect(() => {
    // Устанавливаем цену 1 для автоматических броней
    const automaticPrices: { [key: string]: string } = {};
    pendingBookings.forEach(booking => {
      if (booking.type === 'automatic') {
        automaticPrices[booking._id] = '1';
      }
    });
    setPriceInputs(prev => ({ ...prev, ...automaticPrices }));
  }, [pendingBookings]);

  const fetchBookings = async () => {
    try {
      // Получаем запросы на бронь
      const requestsResponse = await fetch('/api/booking-requests');
      const requestsData = await requestsResponse.json();
      if (requestsData.success) {
        setPendingBookings(requestsData.requests);
      }

      // Получаем подтвержденные брони
      const confirmedResponse = await fetch('/api/confirmed-bookings');
      const confirmedData = await confirmedResponse.json();
      if (confirmedData.success) {
        setConfirmedBookings(confirmedData.bookings);
      }
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const handlePriceChange = (bookingId: string, value: string) => {
    setPriceInputs(prev => ({ ...prev, [bookingId]: value }));
    setPriceErrors(prev => ({ ...prev, [bookingId]: '' }));
  };

  const handleConfirmBooking = async (bookingId: string) => {
    const price = parseFloat(priceInputs[bookingId]);
    if (!price || price <= 0) {
      setPriceErrors(prev => ({ 
        ...prev, 
        [bookingId]: 'Укажите корректную цену' 
      }));
      return;
    }

    try {
      const response = await fetch(`/api/bookings/${bookingId}/confirm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ totalPrice: price }),
      });

      const data = await response.json();
      if (data.success) {
        setPriceInputs(prev => {
          const newInputs = { ...prev };
          delete newInputs[bookingId];
          return newInputs;
        });
        fetchBookings();
      }
    } catch (error) {
      console.error('Error confirming booking:', error);
    }
  };

  const handleCancelBooking = async (bookingId: string) => {
    try {
      const response = await fetch(`/api/bookings/${bookingId}/cancel`, {
        method: 'POST',
      });

      const data = await response.json();
      if (data.success) {
        fetchBookings();
      }
    } catch (error) {
      console.error('Error cancelling booking:', error);
    }
  };

  const renderBookingCard = (booking: BookingRequest, isPending: boolean = false) => (
    <div key={booking._id} className="booking-card" data-type={booking.type}>
      <div className="booking-info">
        <div className="user-info">
          <h3>{booking.userId.name}</h3>
          <a href={`tel:${booking.userId.phone}`} className="phone">
            {booking.userId.phone}
          </a>
        </div>
        
        <div className="booking-details">
          {isPending && (
            <span className="date">{formatDateKey(booking.date)}</span>
          )}
          <span className="time">
            {formatTimeSlots(booking.timeSlots)}
          </span>
          <span className="room">Кабинет: {booking.roomId.name}</span>
          
          {/* Показываем дополнительную информацию только для неавтоматических броней */}
          {booking.type !== 'automatic' && (
            <>
              <span className="type">
                {booking.type === 'group' ? 'Групповое' : 'Индивидуальное'} занятие
              </span>
              <span className="people">{booking.numberOfPeople} чел.</span>
              <span className="event-type">Мероприятие: {booking.eventType || 'Не указано'}</span>
            </>
          )}
          
          {!isPending && booking.totalPrice && (
            <span className="price">{booking.totalPrice} руб.</span>
          )}
        </div>
      </div>

      <div className="booking-actions">
        {isPending ? (
          <>
            <div className="action-buttons">
              <button 
                className="confirm-button"
                onClick={() => handleConfirmBooking(booking._id)}
                disabled={!priceInputs[booking._id]}
              >
                <img src={checkIcon} alt="Подтвердить" />
              </button>
              <button 
                className="delete-button"
                onClick={() => handleCancelBooking(booking._id)}
              >
                <img src={trashIcon} alt="Удалить" />
              </button>
            </div>
            <div className="price-input-group">
              <input
                type="number"
                placeholder="Цена"
                value={priceInputs[booking._id] || ''}
                onChange={(e) => handlePriceChange(booking._id, e.target.value)}
                className={priceErrors[booking._id] ? 'error' : ''}
              />
              {priceErrors[booking._id] && (
                <span className="error-message">{priceErrors[booking._id]}</span>
              )}
            </div>
          </>
        ) : (
          <div className="action-buttons">
            <button 
              className="delete-button"
              onClick={() => handleCancelBooking(booking._id)}
            >
              <img src={trashIcon} alt="Удалить" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const groupBookingsByDate = (bookings: BookingRequest[]): GroupedBookings => {
    const grouped = bookings.reduce((acc, booking) => {
      const dateKey = formatDateKey(booking.date);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(booking);
      return acc;
    }, {} as GroupedBookings);

    // Сортируем даты
    const sortedGrouped: GroupedBookings = {};
    Object.keys(grouped)
      .sort((a, b) => new Date(grouped[a][0].date).getTime() - new Date(grouped[b][0].date).getTime())
      .forEach(key => {
        sortedGrouped[key] = grouped[key];
      });

    return sortedGrouped;
  };

  const renderConfirmedBookings = () => {
    const groupedBookings = groupBookingsByDate(filteredConfirmedBookings);

    return Object.entries(groupedBookings).map(([dateKey, bookings]) => (
      <div key={dateKey} className="date-group">
        <h3 className="date-header">{dateKey}</h3>
        {bookings.map(booking => renderBookingCard(booking))}
      </div>
    ));
  };

  const extractDateParts = (dateStr: string): { day?: string; month?: string; year?: string } => {
    const parts = dateStr.split(/[\s\.]+/);
    const result: { day?: string; month?: string; year?: string } = {};
    
    for (const part of parts) {
      if (part.match(/^\d{1,2}$/)) {
        result.day = part.padStart(2, '0');
      } else if (part.match(/^\d{4}$/)) {
        result.year = part;
      } else {
        // Нормализуем месяц
        const normalizedPart = normalizeString(part);
        const monthMap: { [key: string]: string } = {
          'янв': 'янв',
          'фев': 'фев',
          'мар': 'мар',
          'апр': 'апр',
          'май': 'мая',
          'июн': 'июн',
          'июл': 'июл',
          'авг': 'авг',
          'сен': 'сен',
          'окт': 'окт',
          'ноя': 'ноя',
          'дек': 'дек'
        };

        for (const [key, value] of Object.entries(monthMap)) {
          if (normalizedPart.includes(key)) {
            result.month = value.toUpperCase() + '.';
            break;
          }
        }
      }
    }
    
    return result;
  };

  const matchDateParts = (dateStr: string, searchParts: { day?: string; month?: string; year?: string }): boolean => {
    if (!Object.keys(searchParts).length) return false;

    const normalizedDate = normalizeString(dateStr);
    
    for (const [key, value] of Object.entries(searchParts)) {
      if (value && !normalizedDate.includes(normalizeString(value))) {
        return false;
      }
    }
    
    return true;
  };

  const filterBookings = (bookings: BookingRequest[], query: string): BookingRequest[] => {
    // 1. Получаем текущую дату в UTC и устанавливаем время в начало дня
    const today = new Date();
    const todayUTC = new Date(Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0, 0, 0, 0
    ));
  
    const bookingsFilteredByDateAndType = bookings.filter(booking => {
      // 2. Преобразуем дату брони в UTC и устанавливаем время в начало дня
      const bookingDate = new Date(booking.date);
      const bookingDateUTC = new Date(Date.UTC(
        bookingDate.getUTCFullYear(),
        bookingDate.getUTCMonth(),
        bookingDate.getUTCDate(),
        0, 0, 0, 0
      ));
  
      // 3. Сравниваем UTC даты
      const isBookingDateValid = bookingDateUTC >= todayUTC;
      const isBookingTypeValid = showAutoBookings || booking.type !== 'automatic';
  
      return isBookingDateValid && isBookingTypeValid;
    });
  
    // 3. Если поисковый запрос пустой, возвращаем результат фильтрации по дате и типу
    if (!query.trim()) {
      return bookingsFilteredByDateAndType;
    }
  
    // 4. Подготавливаем поисковые термины, разбивая запрос на отдельные слова
    const searchTerms = normalizeString(query).split(' ');
  
    // 5. Получаем части даты из поискового запроса (день, месяц, год)
    const dateParts = extractDateParts(query);
  
    // 6. Фильтруем брони по всем критериям поиска
    return bookingsFilteredByDateAndType.filter(booking => {
      // Получаем отформатированную строку даты для текущей брони
      const dateString = formatDateKey(booking.date);
  
      // Проверяем совпадение по дате
      const matchesDate = matchDateParts(dateString, dateParts);
  
      // Нормализуем имя пользователя для поиска
      const normalizedUserName = normalizeString(booking.userId.name);
      // Проверяем, содержит ли имя пользователя хотя бы один из поисковых терминов
      const matchesName = searchTerms.some(term => 
        normalizedUserName.includes(normalizeString(term))
      );
  
      // Нормализуем название комнаты для поиска
      const normalizedRoomName = normalizeString(booking.roomId.name);
      // Проверяем, содержит ли название комнаты хотя бы один из поисковых терминов
      const matchesRoom = searchTerms.some(term => 
        normalizedRoomName.includes(normalizeString(term))
      );
  
      // Подготавливаем телефонный номер для поиска, оставляя только цифры
      const phoneDigitsOnly = booking.userId.phone.replace(/\D/g, '');
      // Получаем только цифры из поискового запроса
      const searchQueryDigitsOnly = query.replace(/\D/g, '');
      // Проверяем, содержится ли последовательность цифр из поиска в номере телефона
      const matchesPhone = searchQueryDigitsOnly ? 
        phoneDigitsOnly.includes(searchQueryDigitsOnly) : false;
  
      // Нормализуем тип мероприятия для поиска
      const normalizedEventType = normalizeString(booking.eventType || '');
      // Проверяем, содержит ли тип мероприятия хотя бы один из поисковых терминов
      const matchesEventType = searchTerms.some(term => 
        normalizedEventType.includes(normalizeString(term))
      );
  
      // Проверяем совпадение по типу брони (индивидуальная/групповая/автоматическая)
      const matchesBookingType = (() => {
        const normalizedSearchQuery = normalizeString(query);
        
        switch (booking.type) {
          case 'individual':
            return 'индивидуальное'.includes(normalizedSearchQuery);
          case 'group':
            return 'групповое'.includes(normalizedSearchQuery);
          case 'automatic':
            return 'автоматическое'.includes(normalizedSearchQuery);
          default:
            return false;
        }
      })();
  
      // Возвращаем true, если бронь соответствует хотя бы одному критерию поиска
      return matchesDate || 
             matchesName || 
             matchesRoom || 
             matchesPhone || 
             matchesEventType || 
             matchesBookingType;
    });
  };

  const renderSearchStats = () => {
    if (!searchQuery) return null;
    
    const pendingCount = filteredPendingBookings.length;
    const confirmedCount = filteredConfirmedBookings.length;
    const totalCount = pendingCount + confirmedCount;

    return (
      <div className="search-stats">
        Найдено броней: {totalCount} 
        {totalCount > 0 && ` (${pendingCount} ожидают, ${confirmedCount} подтверждены)`}
      </div>
    );
  };

  const filteredPendingBookings = filterBookings(pendingBookings, searchQuery);
  const filteredConfirmedBookings = filterBookings(confirmedBookings, searchQuery);

  return (
    <div className="booking-requests-screen">
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Поиск по дате (например: 1 дек), имени, кабинету..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <label className="auto-bookings-toggle">
          <div className="toggle-label">Автоброни</div>
          <div 
            className={`toggle-switch ${showAutoBookings ? 'active' : ''}`}
            onClick={() => setShowAutoBookings(!showAutoBookings)}
          >
            <div className="toggle-handle"></div>
          </div>
        </label>
        {renderSearchStats()}
      </div>

      <div className="pending-bookings">
        <h2>Запросы на бронь</h2>
        {filteredPendingBookings.map(booking => renderBookingCard(booking, true))}
        {filteredPendingBookings.length === 0 && (
          <p className="no-bookings">
            {searchQuery ? 'Ничего не найдено' : 'Нет активных запросов на бронь'}
          </p>
        )}
      </div>

      <div className="confirmed-bookings">
        <h2>Подтверждённые брони</h2>
        {renderConfirmedBookings()}
        {filteredConfirmedBookings.length === 0 && (
          <p className="no-bookings">
            {searchQuery ? 'Ничего не найдено' : 'Нет подтверждённых броней'}
          </p>
        )}
      </div>
    </div>
  );
};

export default BookingRequestsScreen;