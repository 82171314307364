import React, { useState, useEffect } from 'react';
import '../styles/BookingRequestsScreen.scss';
import trashIcon from '../icons/trash.svg';

interface TimeSlot {
  start: string;
  end: string;
}

interface BookingUser {
  name: string;
  phone: string;
}

interface BookingRoom {
  name: string;
}

interface Booking {
  _id: string;
  userId: BookingUser | null;
  roomId: BookingRoom | null;
  date: string;
  timeSlots: TimeSlot[];
  type: 'individual' | 'group' | 'automatic';
  numberOfPeople: number;
  eventType: string;
  status: 'pending' | 'confirmed' | 'cancelled';
  totalPrice?: number;
}

interface GroupedBookings {
  [key: string]: Booking[];
}

// Утилиты для форматирования времени
const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const normalizeTime = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const formatTimeSlots = (timeSlots: TimeSlot[]): string => {
  if (!Array.isArray(timeSlots) || timeSlots.length === 0) {
    return '';
  }

  // Сортируем слоты по времени начала
  const sortedSlots = [...timeSlots].sort((a, b) => 
    timeToMinutes(a.start) - timeToMinutes(b.start)
  );

  const mergedSlots: { start: string; end: string }[] = [];
  let currentSlot = { ...sortedSlots[0] };

  for (let i = 1; i < sortedSlots.length; i++) {
    if (timeToMinutes(currentSlot.end) === timeToMinutes(sortedSlots[i].start)) {
      // Слоты можно объединить
      currentSlot.end = sortedSlots[i].end;
    } else {
      // Слоты нельзя объединить
      mergedSlots.push({ ...currentSlot });
      currentSlot = { ...sortedSlots[i] };
    }
  }
  mergedSlots.push(currentSlot);

  // Форматируем результат с нормализацией времени
  return mergedSlots
    .map(slot => `${normalizeTime(slot.start)}-${normalizeTime(slot.end)}`)
    .join(', ');
};

const formatDateKey = (dateString: string) => {
  const date = new Date(dateString);
  // Создаем новую дату в UTC
  const utcDate = new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  ));
  
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const months = [
    'ЯНВ.', 'ФЕВ.', 'МАР.', 'АПР.', 'МАЯ', 'ИЮН.', 
    'ИЮЛ.', 'АВГ.', 'СЕНТ.', 'ОКТ.', 'НОЯБ.', 'ДЕК.'
  ];
  const month = months[utcDate.getUTCMonth()];
  const year = utcDate.getUTCFullYear();
  const weekday = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'][utcDate.getUTCDay()];
  
  return `${day} ${month} ${year} (${weekday})`;
};

const AllBookingsScreen: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [deletingBookingId, setDeletingBookingId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAutoBookings, setShowAutoBookings] = useState(true);
  const [showPastBookings, setShowPastBookings] = useState(true);

  useEffect(() => {
    fetchAllBookings();
  }, []);

  const fetchAllBookings = async () => {
    try {
      const response = await fetch('/api/all-bookings');
      const data = await response.json();
      if (data.success) {
        // Фильтруем брони, у которых нет userId или roomId
        const validBookings = data.bookings.filter(
          (booking: Booking) => booking.userId && booking.roomId
        );
        setBookings(validBookings);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching all bookings:', error);
      setLoading(false);
    }
  };

  const handleDeleteBooking = async (bookingId: string) => {
    setDeletingBookingId(bookingId);
    
    try {
      const response = await fetch(`/api/bookings/${bookingId}`, {
        method: 'DELETE'
      });

      const data = await response.json();
      if (data.success) {
        setTimeout(() => {
          fetchAllBookings();
          setDeletingBookingId(null);
        }, 300);
      }
    } catch (error) {
      console.error('Error deleting booking:', error);
      setDeletingBookingId(null);
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'Подтверждено';
      case 'pending':
        return 'На рассмотрении';
      case 'cancelled':
        return 'Отменено';
      default:
        return status;
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'status-confirmed';
      case 'pending':
        return 'status-pending';
      case 'cancelled':
        return 'status-cancelled';
      default:
        return '';
    }
  };

  const groupBookingsByDate = (bookings: Booking[]): GroupedBookings => {
    const grouped = bookings.reduce((acc, booking) => {
      const dateKey = formatDateKey(booking.date);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(booking);
      return acc;
    }, {} as GroupedBookings);

    const sortedGrouped: GroupedBookings = {};
    Object.keys(grouped)
      .sort((a, b) => new Date(grouped[a][0].date).getTime() - new Date(grouped[b][0].date).getTime())
      .forEach(key => {
        sortedGrouped[key] = grouped[key];
      });

    return sortedGrouped;
  };

  const renderBookingCard = (booking: Booking) => {
    // Проверяем наличие необходимых данных
    if (!booking?.userId || !booking?.roomId) {
      console.warn('Booking with missing data:', booking);
      return null;
    }

    return (
      <div key={booking._id} className="booking-card" data-type={booking.type}>
        <div className="booking-info">
          <div className="user-info">
            <h3>{booking.userId.name || 'Неизвестный пользователь'}</h3>
            {booking.userId.phone && (
              <a href={`tel:${booking.userId.phone}`} className="phone">
                {booking.userId.phone}
              </a>
            )}
          </div>
          
          <div className="booking-details">
            <span className="date">{formatDateKey(booking.date)}</span>
            <span className="time">
              {formatTimeSlots(booking.timeSlots)}
            </span>
            <span className="room">Кабинет: {booking.roomId.name || 'Неизвестный кабинет'}</span>
            <span className="type">
              {booking.type === 'group' ? 'Групповое' : 
               booking.type === 'individual' ? 'Индивидуальное' : 
               'Автоматическое'} занятие
            </span>
            <span className="people">{booking.numberOfPeople} чел.</span>
            <span className="event-type">Мероприятие: {booking.eventType || 'Не указано'}</span>
            {booking.totalPrice && (
              <span className="price">{booking.totalPrice} руб.</span>
            )}
            <span className={`status ${getStatusClass(booking.status)}`}>
              {getStatusLabel(booking.status)}
            </span>
          </div>
        </div>

        <div className="booking-actions">
          <div className="action-buttons">
            <button 
              className="delete-button"
              onClick={() => handleDeleteBooking(booking._id)}
              disabled={deletingBookingId === booking._id}
            >
              <img src={trashIcon} alt="Удалить" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const filterBookings = (bookings: Booking[]): Booking[] => {
    return bookings.filter(booking => {
      // Фильтр по автоброням
      if (!showAutoBookings && booking.type === 'automatic') {
        return false;
      }

      // Фильтр по прошедшим броням
      if (!showPastBookings) {
        const bookingDate = new Date(booking.date);
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);  // Устанавливаем вчерашнюю дату
        yesterday.setHours(0, 0, 0, 0);  // Начало вчерашнего дня
        
        if (bookingDate < yesterday) {
          return false;
        }
      }

      return true;
    });
  };

  const renderBookings = () => {
    const filteredBookings = filterBookings(bookings);
    const groupedBookings = groupBookingsByDate(filteredBookings);

    return Object.entries(groupedBookings).map(([dateKey, bookings]) => (
      <div key={dateKey} className="date-group">
        <h3 className="date-header">{dateKey}</h3>
        {bookings.map(booking => renderBookingCard(booking))}
      </div>
    ));
  };

  if (loading) {
    return (
      <div className="booking-requests-screen">
        <div className="loading">Загрузка броней...</div>
      </div>
    );
  }

  return (
    <div className="booking-requests-screen">
      <div className="search-container">
        <label className="auto-bookings-toggle">
          <div className="toggle-label">Автоброни</div>
          <div 
            className={`toggle-switch ${showAutoBookings ? 'active' : ''}`}
            onClick={() => setShowAutoBookings(!showAutoBookings)}
          >
            <div className="toggle-handle"></div>
          </div>
        </label>
        <label className="auto-bookings-toggle">
          <div className="toggle-label">Прошедшие брони</div>
          <div 
            className={`toggle-switch ${showPastBookings ? 'active' : ''}`}
            onClick={() => setShowPastBookings(!showPastBookings)}
          >
            <div className="toggle-handle"></div>
          </div>
        </label>
      </div>
      
      <h2>Все брони</h2>
      {renderBookings()}
      {bookings.length === 0 && (
        <p className="no-bookings">Нет броней</p>
      )}
    </div>
  );
};

export default AllBookingsScreen;