import React, { useState, useRef, ChangeEvent } from 'react';
import { ReactComponent as Loader } from '../content/loader.svg';

interface ConfirmationModalProps {
  selectedDate: Date | null;
  getFormattedDateForConfirmation: (date: Date | null) => string;
  showMyBookings: () => void;
  user: { name: string };
  calculatedPrice: number;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ 
  selectedDate, 
  getFormattedDateForConfirmation, 
  showMyBookings,
  user,
  calculatedPrice 
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      alert('Файл слишком большой. Максимальный размер: 10MB');
      return;
    }

    setIsUploading(true);
    
    try {
      // Читаем файл как arrayBuffer
      const buffer = await file.arrayBuffer();
      const blob = new Blob([buffer], { type: file.type });

      // Создаем FormData и добавляем файл и имя пользователя
      const formData = new FormData();
      formData.append('photo', blob, file.name);
      formData.append('userName', user.name);

      const response = await fetch('/api/upload-payment', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to upload photo');
      }

      showMyBookings();
    } catch (error) {
      console.error('Error uploading photo:', error);
      alert('Не удалось отправить фото. Пожалуйста, отправьте фото в личные сообщения получателю.');
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="confirmation-modal">
      <Loader className="loader-svg" />
      <div className="confirmation-text">
        <p>Бронь на {getFormattedDateForConfirmation(selectedDate)} ждёт подтверждения!</p>
        <p>Стоимость брони: {calculatedPrice}₽</p>
        <p>А вы пока можете оплатить бронирование</p>
      </div>
      <div className="confirmation-rules">
        <p>Бронь подтвердится в случае, если вы её оплатили<br/>
          <span className="important">(подтверждение только по предоплате)</span>
        </p>
        <p>При отмене брони за 2 дня – предоплата возвращается полностью</p>
        <p>При отмене брони за 1 день – возврат 50%</p>
        <p>При отмене брони в день занятия предоплата не возвращается</p>
      </div>
      <div className="confirmation-actions">
        <input 
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          accept="image/*"
          style={{ display: 'none' }}
        />
        <button 
          className="upload-button"
          onClick={() => fileInputRef.current?.click()}
          disabled={isUploading}
        >
          {isUploading ? 'Отправка...' : 'Прикрепить фотографию перевода'}
        </button>
        <button 
          className="submit-button"
          onClick={showMyBookings}
          disabled={isUploading}
        >
          Хорошо!
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;