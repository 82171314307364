import React, { useState, useEffect } from 'react';
import { Room, TimeSlot, User } from '../types';
import { DateHandler } from '../utils/dateHandler';
import '../styles/BookingScreen.scss';
import ConfirmationModal from './ConfirmationModal';

interface BookingScreenProps {
  room: Room;
  user: User;
  onBack: () => void;
  showMyBookings: () => void;
}

interface BookedSlot {
  timeSlots: TimeSlot[];
  userId: string;
  status: 'confirmed' | 'pending';
}

const BookingScreen: React.FC<BookingScreenProps> = ({ room, user, onBack, showMyBookings }) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlot[]>([]);
  const [bookedSlots, setBookedSlots] = useState<BookedSlot[]>([]);
  const [peopleCount, setPeopleCount] = useState<string>('');
  const [eventType, setEventType] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);

  const DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
  const MONTHS = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

  // Эффект для анимации появления кнопки
  useEffect(() => {
    if (step === 2) {
      setIsButtonVisible(selectedTimeSlots.length > 0);
    } else if (step === 3) {
      setIsButtonVisible(peopleCount !== '' && eventType !== '');
    }
  }, [selectedTimeSlots, step, peopleCount, eventType]);

  const calculatePrice = (): number => {
    const basePrice = parseInt(peopleCount) === 1 ? room.individualPrice : room.groupPrice;
    const halfHourBlocks = selectedTimeSlots.length;
    return (basePrice / 2) * halfHourBlocks;
  };

  const getDatesForNextFiveWeeks = () => {
    const dates = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const firstMonday = new Date(today);
    while (firstMonday.getDay() !== 1) {
      firstMonday.setDate(firstMonday.getDate() - 1);
    }
    
    const fiveWeeksFromNow = new Date(firstMonday);
    fiveWeeksFromNow.setDate(firstMonday.getDate() + (7 * 5));
  
    for (let d = new Date(firstMonday); d < fiveWeeksFromNow; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }
    
    return dates;
  };

  const generateTimeSlots = () => {
    const slots: TimeSlot[] = [];
    for (let hour = 7; hour < 24; hour++) {
      for (let minute of ['00', '30']) {
        const start = `${hour}:${minute}`;
        const endHour = minute === '30' ? hour + 1 : hour;
        const endMinute = minute === '30' ? '00' : '30';
        const end = `${endHour}:${endMinute}`;
        slots.push({ start, end });
      }
    }
    return slots;
  };

  const fetchBookingsForDate = async (date: Date) => {
    try {
      const utcDate = DateHandler.localToUTC(date);
      const formattedDate = utcDate.toISOString();
      
      const response = await fetch(
        `/api/bookings?roomId=${room._id}&date=${formattedDate}`
      );
      const data = await response.json();
      
      if (data.success) {
        setBookedSlots(data.bookings);
      }
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    setStep(2);
    setSelectedTimeSlots([]);
  };

  const handleTimeSlotClick = (slot: TimeSlot) => {
    const isSlotBooked = isTimeSlotBooked(slot, bookedSlots);
    const isMySlot = isMyBooking(slot, bookedSlots);
  
    if (isSlotBooked || isMySlot) {
      return;
    }
  
    setSelectedTimeSlots(prev => {
      const isSelected = prev.some(
        s => s.start === slot.start && s.end === slot.end
      );
      if (isSelected) {
        return prev.filter(s => s.start !== slot.start || s.end !== slot.end);
      } else {
        const hasOverlap = prev.some(selectedSlot => {
          const selectedStart = parseInt(selectedSlot.start.split(':')[0]) * 60 + 
                              parseInt(selectedSlot.start.split(':')[1]);
          const selectedEnd = parseInt(selectedSlot.end.split(':')[0]) * 60 + 
                            parseInt(selectedSlot.end.split(':')[1]);
          const newStart = parseInt(slot.start.split(':')[0]) * 60 + 
                          parseInt(slot.start.split(':')[1]);
          const newEnd = parseInt(slot.end.split(':')[0]) * 60 + 
                        parseInt(slot.end.split(':')[1]);
  
          return (newStart >= selectedStart && newStart < selectedEnd) ||
                 (newEnd > selectedStart && newEnd <= selectedEnd) ||
                 (newStart <= selectedStart && newEnd >= selectedEnd);
        });
  
        if (hasOverlap) {
          return prev;
        }
  
        return [...prev, slot];
      }
    });
  };

  const handleBookingSubmit = async () => {
    if (!selectedDate || selectedTimeSlots.length === 0 || !peopleCount || !eventType) 
      return;

    try {
      const localDate = new Date(selectedDate);
      const timestamp = localDate.getTime();
      
      const response = await fetch('/api/bookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userPhone: user.phone,
          roomId: room._id,
          date: timestamp,
          timeSlots: selectedTimeSlots,
          type: parseInt(peopleCount) === 1 ? 'individual' : 'group',
          numberOfPeople: parseInt(peopleCount),
          eventType
        }),
      });

      const data = await response.json();
      if (data.success) {
        setShowConfirmation(true);
      }
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const formatDate = (date: Date) => {
    return `${date.getDate()}.${date.getMonth() + 1}`;
  };

  const getFormattedDateForConfirmation = (date: Date | null) => {
    if (!date) return '';
    return `${date.getDate()} ${MONTHS[date.getMonth()]}`;
  };

  const isTimeSlotBooked = (slot: TimeSlot, bookedSlots: BookedSlot[]) => {
    return bookedSlots.some(booking => 
      booking.userId !== user._id &&
      booking.status === 'confirmed' &&
      booking.timeSlots.some(
        bookedSlot => 
          bookedSlot.start === slot.start && 
          bookedSlot.end === slot.end
      )
    );
  };
  
  const isMyBooking = (slot: TimeSlot, bookedSlots: BookedSlot[]) => {
    return bookedSlots.some(booking => 
      booking.userId === user._id &&
      booking.status === 'confirmed' &&
      booking.timeSlots.some(
        bookedSlot => 
          bookedSlot.start === slot.start && 
          bookedSlot.end === slot.end
      )
    );
  };

  useEffect(() => {
    if (selectedDate) {
      fetchBookingsForDate(selectedDate);
    }
  }, [selectedDate]);

  if (showConfirmation) {
    return (
      <div className="booking-container">
        <ConfirmationModal
          selectedDate={selectedDate}
          getFormattedDateForConfirmation={getFormattedDateForConfirmation}
          showMyBookings={showMyBookings}
          user={user}
          calculatedPrice={calculatePrice()} // Добавляем передачу цены
        />
      </div>
    );
  }

  return (
    <div className="booking-container">
      <div className="navigation-tabs">
        <button className="tab-button active" onClick={onBack}>
          Все помещения
        </button>
        <button className="tab-button inactive" onClick={showMyBookings}>
          Мои записи
        </button>
      </div>

      <div className="room-photos">
        {room.photos?.map((photo, index) => (
          <div key={index} className="photo-container">
            <img src={photo} alt={`${room.name} - фото ${index + 1}`} />
          </div>
        ))}
      </div>

      <div className="booking-steps">
        <div className={`booking-step ${step === 1 ? 'active' : ''}`}>
          <h2 onClick={() => setStep(1)}>1. Выберите дату</h2>
          {step === 1 && (
            <div className="calendar">
              <div className="weekdays">
                {DAYS.map(day => (
                  <div key={day} className="weekday">{day}</div>
                ))}
              </div>
              <div className="dates">
                {getDatesForNextFiveWeeks().map((date, index) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  const isDisabled = date < today;
                  
                  return (
                    <button
                      key={index}
                      className={`date-button ${
                        selectedDate?.toDateString() === date.toDateString() 
                          ? 'active' 
                          : ''
                      } ${isDisabled ? 'disabled' : ''}`}
                      onClick={() => !isDisabled && handleDateClick(date)}
                      disabled={isDisabled}
                      style={{ opacity: isDisabled ? 0.5 : 1 }}
                    >
                      {date.getDate()}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {step >= 2 && selectedDate && (
          <div className={`booking-step ${step === 2 ? 'active' : ''}`}>
            <h2 onClick={() => setStep(2)}>
              2. Выберите время на {formatDate(selectedDate)}
            </h2>
            {step === 2 && (
              <div className="time-slots">
                {generateTimeSlots().map((slot, index) => {
                  const isMySlot = isMyBooking(slot, bookedSlots);
                  const isBooked = isTimeSlotBooked(slot, bookedSlots);
                  const isSelected = selectedTimeSlots.some(
                    s => s.start === slot.start && s.end === slot.end
                  );

                  let slotClassName = 'time-slot';
                  if (isSelected) slotClassName = 'selected';
                  else if (isMySlot) slotClassName += ' my-booking';
                  else if (isBooked) slotClassName += ' booked';

                  return (
                    <button
                      key={index}
                      className={slotClassName}
                      onClick={() => handleTimeSlotClick(slot)}
                      disabled={isBooked && !isMySlot}
                    >
                      {slot.start}
                      <span className="time-range">до {slot.end}</span>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {step >= 3 && (
          <div className={`booking-step ${step === 3 ? 'active' : ''}`}>
            <h2>3. Доп. информация</h2>
            <input
              type="number"
              inputMode="numeric"
              placeholder="КОЛИЧЕСТВО ЛЮДЕЙ (ЧИСЛО)"
              value={peopleCount}
              onChange={(e) => setPeopleCount(e.target.value)}
              className="people-input"
            />
            <input
              type="text"
              placeholder="КАКОЕ У ВАС МЕРОПРИЯТИЕ?"
              value={eventType}
              onChange={(e) => setEventType(e.target.value)}
              className="event-input"
            />
          </div>
        )}
      </div>

      {isButtonVisible && (
        <div className="fixed-button-container">
          {step === 2 && (
            <button 
              className="fixed-action-button"
              onClick={() => setStep(3)}
            >
              Закрепить время
            </button>
          )}
          
          {step === 3 && peopleCount && eventType && (
            <button 
              className="fixed-action-button"
              onClick={handleBookingSubmit}
            >
              Записаться ({calculatePrice()}₽)
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingScreen;