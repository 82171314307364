import React, { useState, useEffect } from 'react';
import '../styles/AutomaticBookingScreen.scss';
import trashIcon from '../icons/trash.svg';
import * as phoneUtils from '../utils/phoneUtils';

interface TimeSlot {
    start: string;
    end: string;
}

interface Room {
    _id: string;
    name: string;
}

interface AutomaticBooking {
  _id: string;
  userId: {
    name: string;
  };
  roomId: {
    name: string;
  };
  userPhone: string;
  dayOfWeek: number;
  timeSlots: TimeSlot[];
}

function generateTimeSlots() {
  const slots = [];
  for (let hour = 7; hour < 24; hour++) {
    for (let minute of ['00', '30']) {
      const start = `${hour}:${minute}`;
      const endHour = minute === '30' ? hour + 1 : hour;
      const endMinute = minute === '30' ? '00' : '30';
      const end = `${endHour}:${endMinute}`;
      slots.push({ start, end });
    }
  }
  return slots;
}

const DAYS = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
const TIME_SLOTS = generateTimeSlots();

// Утилиты для форматирования времени
const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

const normalizeTime = (time: string): string => {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const formatTimeSlots = (timeSlots: TimeSlot[]): string => {
    if (!Array.isArray(timeSlots) || timeSlots.length === 0) {
        return '';
    }

    // Сортируем слоты по времени начала
    const sortedSlots = [...timeSlots].sort((a, b) => 
        timeToMinutes(a.start) - timeToMinutes(b.start)
    );

    const mergedSlots: { start: string; end: string }[] = [];
    let currentSlot = { ...sortedSlots[0] };

    for (let i = 1; i < sortedSlots.length; i++) {
        if (timeToMinutes(currentSlot.end) === timeToMinutes(sortedSlots[i].start)) {
            // Слоты можно объединить
            currentSlot.end = sortedSlots[i].end;
        } else {
            // Слоты нельзя объединить
            mergedSlots.push({ ...currentSlot });
            currentSlot = { ...sortedSlots[i] };
        }
    }
    mergedSlots.push(currentSlot);

    // Форматируем результат с нормализацией времени
    return mergedSlots
        .map(slot => `${normalizeTime(slot.start)}-${normalizeTime(slot.end)}`)
        .join(', ');
};

const AutomaticBookingScreen: React.FC = () => {
    const [phone, setPhone] = useState<string>('+7 ');
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [selectedSlots, setSelectedSlots] = useState<TimeSlot[]>([]);
    const [bookings, setBookings] = useState<AutomaticBooking[]>([]);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [isCheckingPhone, setIsCheckingPhone] = useState<boolean>(false);

    useEffect(() => {
        fetchBookings();
        fetchRooms();
    }, []);

    const fetchRooms = async () => {
        try {
            const response = await fetch('/api/rooms');
            const data = await response.json();
            if (data.success) {
                setRooms(data.rooms);
            }
        } catch (error) {
            console.error('Error fetching rooms:', error);
        }
    };

    const fetchBookings = async () => {
        try {
            const response = await fetch('/api/automatic-bookings');
            const data = await response.json();
            if (data.success) {
                setBookings(data.bookings);
            }
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    const handleRoomChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRoom(e.target.value);
    };

    const handleSlotClick = (slot: TimeSlot) => {
        const isSelected = selectedSlots.some(
            s => s.start === slot.start && s.end === slot.end
        );

        if (isSelected) {
            setSelectedSlots(selectedSlots.filter(
                s => s.start !== slot.start || s.end !== slot.end
            ));
        } else {
            setSelectedSlots([...selectedSlots, slot]);
        }
    };

    const checkUserExists = async (phoneNumber: string) => {
        try {
            const response = await fetch('/api/auth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone: phoneNumber }),
            });

            const data = await response.json();
            return data.success;
        } catch (error) {
            console.error('Error checking user:', error);
            return false;
        }
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formatted = phoneUtils.formatPhoneNumber(e.target.value);
        setPhone(formatted);
        setPhoneError('');
    };

    const handlePhoneBlur = async () => {
        if (phone.length >= 12) {
            setIsCheckingPhone(true);
            const normalizedPhone = phoneUtils.normalizePhone(phone);
            const exists = await checkUserExists(normalizedPhone);
            
            if (!exists) {
                setPhoneError('Пользователь с таким номером не найден');
            } else {
                setPhoneError('');
            }
            setIsCheckingPhone(false);
        }
    };

    const handleCreateBooking = async () => {
        try {
            const normalizedPhone = phoneUtils.normalizePhone(phone);
            
            // Повторная проверка перед созданием брони
            const userExists = await checkUserExists(normalizedPhone);
            if (!userExists) {
                setPhoneError('Пользователь с таким номером не найден');
                return;
            }

            const response = await fetch('/api/automatic-bookings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userPhone: normalizedPhone,
                    roomId: selectedRoom,
                    dayOfWeek: selectedDay,
                    timeSlots: selectedSlots
                }),
            });

            const data = await response.json();
            if (data.success) {
                setPhone('+7 ');
                setSelectedDay(null);
                setSelectedSlots([]);
                setSelectedRoom('');
                setPhoneError('');
                fetchBookings();
            }
        } catch (error) {
            console.error('Error creating booking:', error);
        }
    };

    const handleDeleteBooking = async (id: string) => {
        try {
            const response = await fetch(`/api/automatic-bookings/${id}`, {
                method: 'DELETE'
            });

            const data = await response.json();
            if (data.success) {
                fetchBookings();
            }
        } catch (error) {
            console.error('Error deleting booking:', error);
        }
    };

    return (
        <div className="automatic-booking-container">
            <div className="booking-form">
                <h2>Создание автоматической брони</h2>

                <div className="input-group">
                    <label>ВЫБЕРИТЕ КАБИНЕТ</label>
                    <select 
                        value={selectedRoom}
                        onChange={handleRoomChange}
                        className="room-select"
                    >
                        <option value="">Выберите кабинет</option>
                        {rooms.map(room => (
                            <option key={room._id} value={room._id}>
                                {room.name}
                            </option>
                        ))}
                    </select>
                </div>
                
                <div className="input-group">
                    <label>НОМЕР ПОЛЬЗОВАТЕЛЯ ДЛЯ БРОНИ</label>
                    <input
                        type="tel"
                        value={phone}
                        onChange={handlePhoneChange}
                        onBlur={handlePhoneBlur}
                        placeholder="+7"
                        className={phoneError ? 'error' : ''}
                    />
                    {isCheckingPhone && <div className="checking-message">Проверка номера...</div>}
                    {phoneError && <div className="error-message">{phoneError}</div>}
                </div>

                <div className="day-selector">
                    <label>ВЫБЕРИТЕ ДЕНЬ НЕДЕЛИ ДЛЯ ЗАПИСИ</label>
                    <div className="days">
                        {DAYS.map((day, index) => (
                            <button
                                key={day}
                                className={`day-button ${selectedDay === index ? 'active' : ''}`}
                                onClick={() => setSelectedDay(index)}
                            >
                                {day}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="time-slots">
                    {TIME_SLOTS.map((slot, index) => (
                        <button
                            key={index}
                            className={`time-slot ${
                                selectedSlots.some(s => s.start === slot.start) ? 'active' : ''
                            }`}
                            onClick={() => handleSlotClick(slot)}
                        >
                            {slot.start}
                            <span className="time-range">до {slot.end}</span>
                        </button>
                    ))}
                </div>

                <button
                    className="create-button"
                    onClick={handleCreateBooking}
                    disabled={!phone || !selectedRoom || selectedDay === null || selectedSlots.length === 0 || !!phoneError}
                >
                    Создать автоматическую бронь
                </button>
            </div>

            <div className="bookings-list">
                {bookings.map(booking => (
                    <div key={booking._id} className="booking-item">
                        <div className="booking-info">
                            <div className="user-info">
                                <span className="name">{booking.userId?.name}</span>
                                <span className="phone">{booking.userPhone}</span>
                            </div>
                            <div className="room-info">
                                Кабинет: {booking.roomId?.name}
                            </div>
                            <div className="time-info">
                                {formatTimeSlots(booking.timeSlots)} по {DAYS[booking.dayOfWeek]}
                            </div>
                        </div>
                        <button
                            className="delete-button"
                            onClick={() => handleDeleteBooking(booking._id)}
                        >
                            <img src={trashIcon} alt="Удалить" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AutomaticBookingScreen;