import { Outlet, useLocation } from 'react-router-dom';
import { User } from '../../types';
import BackButton from '../molecules/BackButton';

interface AdminLayoutProps {
  user: User;
  onLogout: () => void;
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ user, onLogout, children }) => {
  const location = useLocation();
  const isMainAdminPage = location.pathname === '/admin';

  if (isMainAdminPage) {
    return <>{children}</>;
  }

  return (
    <>
      <Outlet />
      <BackButton />
    </>
  );
};

export default AdminLayout;