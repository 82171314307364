import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../types';
import * as phoneUtils from '../utils/phoneUtils';

interface RegistrationScreenProps {
  phone: string;
  onRegister: (user: User) => void;
}

const RegistrationScreen: React.FC<RegistrationScreenProps> = ({ phone, onRegister }) => {
  const [name, setName] = useState<string>('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    const normalizedPhone = phoneUtils.normalizePhone(phone);
    
    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          phone: normalizedPhone, 
          name 
        }),
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('userPhone', normalizedPhone);
        onRegister(data.user);
        
        // После успешной регистрации перенаправляем на профиль
        navigate('/profile');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="auth-card">
      <h1>Вы в первый раз вошли в систему. Введите своё имя и фамилию</h1>
      <div className="input-group">
        <label>Имя и фамилия</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Иван Иванов"
        />
      </div>
      <button 
        onClick={handleRegister}
        disabled={name.trim().length < 3}
      >
        Регистрация
      </button>
    </div>
  );
};

export default RegistrationScreen;