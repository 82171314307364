import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import LoginScreen from '../components/LoginScreen';
import RegistrationScreen from '../components/RegistrationScreen';
import ProfileScreen from '../components/ProfileScreen';
import AdminScreen from '../components/AdminScreen';
import RoomManagementScreen from '../components/RoomManagementScreen';
import AutomaticBookingScreen from '../components/AutomaticBookingScreen';
import BookingRequestsScreen from '../components/BookingRequestsScreen';
import AllBookingsScreen from '../components/AllBookingsScreen';
import ProtectedLayout from '../components/layouts/ProtectedLayout';
import AdminLayout from '../components/layouts/AdminLayout';
import NotFound from '../components/errors/NotFound';
import LandingPage from '../components/LandingPage';
import { User } from '../types';

interface RoutesProps {
  user: User | null;
  onLogin: (user: User, phone: string) => void;
  onLogout: () => void;
  onRegistrationNeeded: (phone: string) => void;
  currentPhone: string;
}

export const createAppRouter = ({
  user,
  onLogin,
  onLogout,
  onRegistrationNeeded,
  currentPhone
}: RoutesProps) => {
  return createBrowserRouter([
    {
        path: '/',
        element: (
          // Для тестирования используем URL параметр ?mode=landing
          window.location.hostname === 'centerbryansk.ru' || 
          new URLSearchParams(window.location.search).get('mode') === 'landing' ? 
            <LandingPage /> :
            <Navigate 
              to={
                user 
                  ? user.role === 'admin' 
                    ? '/admin' 
                    : '/profile'
                  : '/login'
              }
              replace
            />
        )
      },
    {
      path: 'login',
      element: user ? (
        <Navigate to={user.role === 'admin' ? '/admin' : '/profile'} replace />
      ) : (
        <LoginScreen 
          onLogin={onLogin} 
          onRegistrationNeeded={onRegistrationNeeded}
          initialPhone={currentPhone}
        />
      ),
    },
    {
      path: 'register',
      element: user ? (
        <Navigate to={user.role === 'admin' ? '/admin' : '/profile'} replace />
      ) : (
        <RegistrationScreen
          phone={currentPhone}
          onRegister={(user) => onLogin(user, currentPhone)}
        />
      ),
    },
    {
      path: 'profile',
      element: (
        <ProtectedLayout user={user} allowedRoles={['user']}>
          <ProfileScreen user={user!} onLogout={onLogout} />
        </ProtectedLayout>
      ),
    },
    {
      path: 'admin',
      element: (
        <ProtectedLayout user={user} allowedRoles={['admin']}>
          <AdminLayout user={user!} onLogout={onLogout}>
            <AdminScreen user={user!} onLogout={onLogout} />
          </AdminLayout>
        </ProtectedLayout>
      ),
      children: [
        {
          path: 'rooms',
          element: <RoomManagementScreen />
        },
        {
          path: 'automatic',
          element: <AutomaticBookingScreen />
        },
        {
          path: 'requests',
          element: <BookingRequestsScreen />
        },
        {
          path: 'all',
          element: <AllBookingsScreen />
        }
      ]
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]);
};

const Routes: React.FC<RoutesProps> = (props) => {
  const router = createAppRouter(props);
  return <RouterProvider router={router} />;
};

export default Routes;