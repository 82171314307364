import React, { useState, useEffect } from 'react';
import './styles.scss';
import { User } from './types';
import Routes from './routes';
import ErrorBoundary from './components/errors/ErrorBoundary';
import * as phoneUtils from './utils/phoneUtils';

const App: React.FC = () => {
  const [currentPhone, setCurrentPhone] = useState<string>('+7 ');
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const savedPhone = localStorage.getItem('userPhone');
    if (savedPhone) {
      handleAutoAuth(savedPhone);
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleAutoAuth = async (savedPhone: string) => {
    try {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: savedPhone }),
      });

      const data = await response.json();

      if (data.success) {
        setUser(data.user);
        setCurrentPhone(phoneUtils.formatPhoneForDisplay(savedPhone));
      }
    } catch (error) {
      console.error('Auto auth error:', error);
      localStorage.removeItem('userPhone');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = (user: User) => {
    setUser(user);
    if (user.phone) {
      localStorage.setItem('userPhone', user.phone);
    }
  };

  const handleRegistrationNeeded = (phone: string) => {
    setCurrentPhone(phone);
  };

  const handleLogout = () => {
    localStorage.removeItem('userPhone');
    setUser(null);
    setCurrentPhone('+7 ');
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="auth-card">
          <h1>Загрузка...</h1>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="container">
        <Routes
          user={user}
          onLogin={handleLogin}
          onLogout={handleLogout}
          onRegistrationNeeded={handleRegistrationNeeded}
          currentPhone={currentPhone}
        />
      </div>
    </ErrorBoundary>
  );
};

export default App;