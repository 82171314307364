import React, { useState, useRef, useEffect } from 'react';
import '../../styles/PhoneInput.scss';

interface PhoneInputProps {
  onLogin: (user: any, phone: string) => void;
  onRegistrationNeeded: (phone: string) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ onLogin, onRegistrationNeeded }) => {
  const [digits, setDigits] = useState(['', '', '', '', '', '', '', '', '', '']);
  const [showError, setShowError] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, digits.length);
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleDigitChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return;

    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    if (value && index < 9) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newDigits.every(digit => digit !== '')) {
      handleCompleteNumber(newDigits);
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !digits[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleCompleteNumber = async (completedDigits: string[]) => {
    const phoneNumber = `8${completedDigits.join('')}`;
    
    try {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: phoneNumber }),
      });

      const data = await response.json();

      if (data.success) {
        onLogin(data.user, phoneNumber);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.error('Auth error:', error);
      setShowError(true);
    }
  };

  return (
    <div className="phone-input-container">
      <h1>Введите свой номер телефона</h1>
      
      <div className="digits-container">
        <span className="prefix">+7</span>
        {digits.map((digit, index) => (
          <input
            key={index}
            ref={el => inputRefs.current[index] = el}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={1}
            value={digit}
            onChange={(e) => handleDigitChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className={`digit-input ${index === digits.findIndex(d => d === '') ? 'current' : ''}`}
          />
        ))}
      </div>

      {showError && (
        <div className="error-container">
          <div className="error-message">
            Вас нет в системе
          </div>
          <button
            className="register-button"
            onClick={() => onRegistrationNeeded(`+7${digits.join('')}`)}
          >
            Зарегистрироваться
          </button>
        </div>
      )}
    </div>
  );
};

export default PhoneInput;