import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <h1>404 - Страница не найдена</h1>
      <p>Запрашиваемая страница не существует</p>
      <button 
        onClick={() => navigate('/')}
        className="back-home-button"
      >
        Вернуться на главную
      </button>
    </div>
  );
};

export default NotFound;