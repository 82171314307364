import React, { useState, useEffect } from 'react';
import { User } from '../../types';
import '../../styles/UserSwitcher.scss';

interface UserSwitcherProps {
  onUserSwitch: (phone: string) => Promise<void>;
}

const UserSwitcher: React.FC<UserSwitcherProps> = ({ onUserSwitch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Используем относительный путь вместо абсолютного URL
      const response = await fetch('/api/users');
      const data = await response.json();
      if (data.success) {
        // Отфильтруем админов из списка и отсортируем по имени
        const filteredUsers = data.users
          .filter((user: User) => user.role === 'user')
          .sort((a: User, b: User) => a.name.localeCompare(b.name));
        setUsers(filteredUsers);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleUserSelect = async (phone: string) => {
    setIsLoading(true);
    try {
      await onUserSwitch(phone);
      setIsOpen(false);
    } catch (error) {
      console.error('Error switching user:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-switcher-container">
      <button 
        className={`user-switcher-button ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isLoading}
      >
        {isLoading ? 'Переключение...' : 'Пользователь для перехода'}
      </button>
      
      {isOpen && (
        <div className="user-list">
          {users.length === 0 ? (
            <div className="user-item no-users">
              Нет доступных пользователей
            </div>
          ) : (
            users.map((user) => (
              <button
                key={user.phone}
                className="user-item"
                onClick={() => handleUserSelect(user.phone)}
                disabled={isLoading}
              >
                {user.name}
              </button>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default UserSwitcher;