import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/BackButton.scss';

interface BackButtonProps {
  to?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ to = '/admin' }) => {
  const navigate = useNavigate();

  return (
    <div className="back-button-container">
      <div className="back-button-wrapper">
        <button 
          className="back-button"
          onClick={() => navigate(to)}
        >
          Вернуться назад
        </button>
      </div>
    </div>
  );
};

export default BackButton;